import React, { Component } from 'react'; //different
import './App.css';
import Carousel from './carousel';
class App extends Component {


    onClick = (e) => {
        e.preventDefault()
        ////console.log('onclick..')
    }
    render() {
        return (
            <>
                <div

                    data-elementor-type="wp-page"
                    data-elementor-id={1198}
                    className="elementor elementor-1198"
                >
                    <section
                        data-particle_enable="false"
                        data-particle-mobile-disabled="false"
                        className="elementor-section elementor-top-section elementor-element elementor-element-54237c00  tabcontent linecutbt01 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="54237c00"
                        data-element_type="section"
                        data-settings='{"background_background":"classic"}'
                    >
                        <div className="elementor-background-overlay" />
                        <div className="elementor-container elementor-column-gap-default">
                            <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1f385437"
                                data-id="1f385437"
                                data-element_type="column"
                            >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <section
                                        data-particle_enable="false"
                                        data-particle-mobile-disabled="false"
                                        className="elementor-section elementor-inner-section elementor-element elementor-element-560ae728 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                        data-id="560ae728"
                                        data-element_type="section"
                                    >
                                        <div className="elementor-container elementor-column-gap-default">
                                            <div
                                                className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-7f492d1f"
                                                data-id="7f492d1f"
                                                data-element_type="column"
                                            >
                                                <div className="elementor-widget-wrap elementor-element-populated e-swiper-container">
                                                    <div
                                                        className="elementor-element elementor-element-318d1b81 elementor-widget elementor-widget-image"
                                                        data-id="318d1b81"
                                                        data-element_type="widget"
                                                        data-widget_type="image.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                            <img
                                                                loading="lazy"
                                                                decoding="async"
                                                                width={788}
                                                                height={812}
                                                                src="/build/web/igame-index-lobby-wm/img/logo.png"
                                                                className="attachment-large size-large wp-image-2648"
                                                                alt=""
                                                                srcSet="/build/web/igame-index-lobby-wm/img/logo.png 788w, /build/web/igame-index-lobby-wm/img/logo.png 291w, /build/web/igame-index-lobby-wm/img/logo.png 768w, /build/web/igame-index-lobby-wm/img/logo.png 24w, /build/web/igame-index-lobby-wm/img/logo.png 36w, h/build/web/igame-index-lobby-wm/img/logo.png 48w"
                                                                sizes="(max-width: 788px) 100vw, 788px"
                                                            />{" "}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="elementor-element elementor-element-2c3c7c8d elementor-widget elementor-widget-heading"
                                                        data-id="2c3c7c8d"
                                                        data-element_type="widget"
                                                        data-widget_type="heading.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                            <div className="elementor-heading-title elementor-size-default">
                                                                คาสิโนออนไลน์ที่ให้บริการ Casino Online
                                                                ยอดนิยมครบจบที่สุดในไทย
                                                            </div>{" "}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="elementor-element elementor-element-eefd82 uael-img-grid-tablet__column-4 uael-img-grid__column-6 uael-img-grid-mobile__column-2 uael-ins-normal elementor-widget elementor-widget-uael-image-gallery"
                                                        data-id="eefd82"
                                                        data-element_type="widget"
                                                        data-settings='{"gallery_columns_tablet":"4","gallery_columns":"6","row_gap":{"unit":"px","size":0,"sizes":[]},"row_gap_tablet":{"unit":"px","size":0,"sizes":[]},"gallery_columns_mobile":"2","column_gap":{"unit":"px","size":20,"sizes":[]},"column_gap_tablet":{"unit":"px","size":"","sizes":[]},"column_gap_mobile":{"unit":"px","size":"","sizes":[]},"row_gap_mobile":{"unit":"px","size":"","sizes":[]},"images_valign":"flex-start"}'
                                                        data-widget_type="uael-image-gallery.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                            <div className="uael-gallery-parent uael-caption- uael-gallery-unjustified">
                                                                <div
                                                                    className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap"
                                                                    data-filter-default="All"
                                                                >
                                                                    <div className="uael-grid-item  uael-img-gallery-item-1">
                                                                        <div className="uael-grid-item-content">
                                                                            <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                    <img
                                                                                        loading="lazy"
                                                                                        decoding="async"
                                                                                        width={322}
                                                                                        height={552}
                                                                                        src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/card-game4-2222.png"
                                                                                        className="attachment-full size-full wp-image-2707"
                                                                                        alt=""
                                                                                        srcSet="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/card-game4-2222.png 322w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/card-game4-2222-175x300.png 175w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/card-game4-2222-14x24.png 14w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/card-game4-2222-21x36.png 21w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/card-game4-2222-28x48.png 28w"
                                                                                        sizes="(max-width: 322px) 100vw, 322px"
                                                                                    />
                                                                                </div>
                                                                                <div className="uael-grid-img-overlay" />
                                                                            </figure>
                                                                        </div>
                                                                    </div>
                                                                    <div className="uael-grid-item  uael-img-gallery-item-2">
                                                                        <div className="uael-grid-item-content">
                                                                            <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                    <img
                                                                                        loading="lazy"
                                                                                        decoding="async"
                                                                                        width={322}
                                                                                        height={552}
                                                                                        src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/casino-2222.png"
                                                                                        className="attachment-full size-full wp-image-2708"
                                                                                        alt=""
                                                                                        srcSet="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/casino-2222.png 322w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/casino-2222-175x300.png 175w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/casino-2222-14x24.png 14w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/casino-2222-21x36.png 21w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/casino-2222-28x48.png 28w"
                                                                                        sizes="(max-width: 322px) 100vw, 322px"
                                                                                    />
                                                                                </div>
                                                                                <div className="uael-grid-img-overlay" />
                                                                            </figure>
                                                                        </div>
                                                                    </div>
                                                                    <div className="uael-grid-item  uael-img-gallery-item-3">
                                                                        <div className="uael-grid-item-content">
                                                                            <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                    <img
                                                                                        loading="lazy"
                                                                                        decoding="async"
                                                                                        width={322}
                                                                                        height={552}
                                                                                        src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/slot22222.png"
                                                                                        className="attachment-full size-full wp-image-2709"
                                                                                        alt=""
                                                                                        srcSet="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/slot22222.png 322w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/slot22222-175x300.png 175w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/slot22222-14x24.png 14w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/slot22222-21x36.png 21w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/slot22222-28x48.png 28w"
                                                                                        sizes="(max-width: 322px) 100vw, 322px"
                                                                                    />
                                                                                </div>
                                                                                <div className="uael-grid-img-overlay" />
                                                                            </figure>
                                                                        </div>
                                                                    </div>
                                                                    <div className="uael-grid-item  uael-img-gallery-item-4">
                                                                        <div className="uael-grid-item-content">
                                                                            <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                    <img
                                                                                        loading="lazy"
                                                                                        decoding="async"
                                                                                        width={322}
                                                                                        height={552}
                                                                                        src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/sport2222.png"
                                                                                        className="attachment-full size-full wp-image-2710"
                                                                                        alt=""
                                                                                        srcSet="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/sport2222.png 322w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/sport2222-175x300.png 175w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/sport2222-14x24.png 14w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/sport2222-21x36.png 21w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/sport2222-28x48.png 28w"
                                                                                        sizes="(max-width: 322px) 100vw, 322px"
                                                                                    />
                                                                                </div>
                                                                                <div className="uael-grid-img-overlay" />
                                                                            </figure>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>{" "}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="elementor-element elementor-element-6adadf9 elementor-arrows-position-inside elementor-pagination-position-outside elementor-widget elementor-widget-image-carousel e-widget-swiper"
                                                        data-id="6adadf9"
                                                        data-element_type="widget"
                                                        data-settings='{"slides_to_show":"3","slides_to_scroll":"1","navigation":"both","autoplay":"yes","pause_on_hover":"yes","pause_on_interaction":"yes","autoplay_speed":5000,"infinite":"yes","speed":500,"image_spacing_custom":{"unit":"px","size":20,"sizes":[]},"image_spacing_custom_tablet":{"unit":"px","size":"","sizes":[]},"image_spacing_custom_mobile":{"unit":"px","size":"","sizes":[]}}'
                                                        data-widget_type="image-carousel.default"
                                                        aria-roledescription="carousel"
                                                        aria-label="Carousel | Horizontal scrolling: Arrow Left & Right"
                                                    >
                                                        {/* <div className="elementor-widget-container">
                                                            <div
                                                                className="elementor-image-carousel-wrapper swiper-container swiper-container-initialized swiper-container-horizontal"
                                                                dir="ltr"
                                                            >
                                                                <div
                                                                    className="elementor-image-carousel swiper-wrapper"
                                                                    aria-live="off"
                                                                    style={{
                                                                        transform: "translate3d(-2240px, 0px, 0px)",
                                                                        transitionDuration: "500ms"
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="swiper-slide swiper-slide-duplicate"
                                                                        role="group"
                                                                        aria-roledescription="slide"
                                                                        aria-label="1 of 3"
                                                                        style={{ width: "353.333px", marginRight: 20 }}
                                                                        aria-hidden="true"
                                                                        inert=""
                                                                        data-swiper-slide-index={0}
                                                                    >
                                                                        <figure className="swiper-slide-inner">
                                                                            <img
                                                                                decoding="async"
                                                                                className="swiper-slide-image"
                                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/คืนยอดเสีย-ทุกวัน-5-Fairbet-1040x1040-1_0.jpg"
                                                                                alt="คืนยอดเสีย-ทุกวัน-5-Fairbet-1040x1040 (1)_0"
                                                                            />
                                                                        </figure>
                                                                    </div>
                                                                    <div
                                                                        className="swiper-slide swiper-slide-duplicate"
                                                                        role="group"
                                                                        aria-roledescription="slide"
                                                                        aria-label="2 of 3"
                                                                        style={{ width: "353.333px", marginRight: 20 }}
                                                                        aria-hidden="true"
                                                                        inert=""
                                                                        data-swiper-slide-index={1}
                                                                    >
                                                                        <figure className="swiper-slide-inner">
                                                                            <img
                                                                                decoding="async"
                                                                                className="swiper-slide-image"
                                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/ฝากแรกของวัน-รับโบนัสฟรี-10-Fairbet-1040x1040_0.jpg"
                                                                                alt="ฝากแรกของวัน-รับโบนัสฟรี-10-Fairbet-1040x1040_0"
                                                                            />
                                                                        </figure>
                                                                    </div>
                                                                    <div
                                                                        className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"
                                                                        role="group"
                                                                        aria-roledescription="slide"
                                                                        aria-label="3 of 3"
                                                                        style={{ width: "353.333px", marginRight: 20 }}
                                                                        data-swiper-slide-index={2}
                                                                    >
                                                                        <figure className="swiper-slide-inner">
                                                                            <img
                                                                                decoding="async"
                                                                                className="swiper-slide-image"
                                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/ถอนได้สูงสุด-50-ล้าน-Fairbet-1040x1040_0.jpg"
                                                                                alt="ถอนได้สูงสุด-50-ล้าน-Fairbet-1040x1040_0"
                                                                            />
                                                                        </figure>
                                                                    </div>
                                                                    <div
                                                                        className="swiper-slide swiper-slide-duplicate-active"
                                                                        role="group"
                                                                        aria-roledescription="slide"
                                                                        aria-label="1 of 3"
                                                                        style={{ width: "353.333px", marginRight: 20 }}
                                                                        aria-hidden="true"
                                                                        inert=""
                                                                        data-swiper-slide-index={0}
                                                                    >
                                                                        <figure className="swiper-slide-inner">
                                                                            <img
                                                                                decoding="async"
                                                                                className="swiper-slide-image"
                                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/คืนยอดเสีย-ทุกวัน-5-Fairbet-1040x1040-1_0.jpg"
                                                                                alt="คืนยอดเสีย-ทุกวัน-5-Fairbet-1040x1040 (1)_0"
                                                                            />
                                                                        </figure>
                                                                    </div>
                                                                    <div
                                                                        className="swiper-slide swiper-slide-duplicate-next"
                                                                        role="group"
                                                                        aria-roledescription="slide"
                                                                        aria-label="2 of 3"
                                                                        style={{ width: "353.333px", marginRight: 20 }}
                                                                        aria-hidden="true"
                                                                        inert=""
                                                                        data-swiper-slide-index={1}
                                                                    >
                                                                        <figure className="swiper-slide-inner">
                                                                            <img
                                                                                decoding="async"
                                                                                className="swiper-slide-image"
                                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/ฝากแรกของวัน-รับโบนัสฟรี-10-Fairbet-1040x1040_0.jpg"
                                                                                alt="ฝากแรกของวัน-รับโบนัสฟรี-10-Fairbet-1040x1040_0"
                                                                            />
                                                                        </figure>
                                                                    </div>
                                                                    <div
                                                                        className="swiper-slide swiper-slide-prev"
                                                                        role="group"
                                                                        aria-roledescription="slide"
                                                                        aria-label="3 of 3"
                                                                        style={{ width: "353.333px", marginRight: 20 }}
                                                                        data-swiper-slide-index={2}
                                                                    >
                                                                        <figure className="swiper-slide-inner">
                                                                            <img
                                                                                decoding="async"
                                                                                className="swiper-slide-image"
                                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/ถอนได้สูงสุด-50-ล้าน-Fairbet-1040x1040_0.jpg"
                                                                                alt="ถอนได้สูงสุด-50-ล้าน-Fairbet-1040x1040_0"
                                                                            />
                                                                        </figure>
                                                                    </div>{" "}
                                                                    <div
                                                                        className="swiper-slide swiper-slide-duplicate swiper-slide-active"
                                                                        role="group"
                                                                        aria-roledescription="slide"
                                                                        aria-label="1 of 3"
                                                                        style={{ width: "353.333px", marginRight: 20 }}
                                                                        aria-hidden="true"
                                                                        inert=""
                                                                        data-swiper-slide-index={0}
                                                                    >
                                                                        <figure className="swiper-slide-inner">
                                                                            <img
                                                                                decoding="async"
                                                                                className="swiper-slide-image"
                                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/คืนยอดเสีย-ทุกวัน-5-Fairbet-1040x1040-1_0.jpg"
                                                                                alt="คืนยอดเสีย-ทุกวัน-5-Fairbet-1040x1040 (1)_0"
                                                                            />
                                                                        </figure>
                                                                    </div>
                                                                    <div
                                                                        className="swiper-slide swiper-slide-duplicate swiper-slide-next"
                                                                        role="group"
                                                                        aria-roledescription="slide"
                                                                        aria-label="2 of 3"
                                                                        style={{ width: "353.333px", marginRight: 20 }}
                                                                        aria-hidden="true"
                                                                        inert=""
                                                                        data-swiper-slide-index={1}
                                                                    >
                                                                        <figure className="swiper-slide-inner">
                                                                            <img
                                                                                decoding="async"
                                                                                className="swiper-slide-image"
                                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/ฝากแรกของวัน-รับโบนัสฟรี-10-Fairbet-1040x1040_0.jpg"
                                                                                alt="ฝากแรกของวัน-รับโบนัสฟรี-10-Fairbet-1040x1040_0"
                                                                            />
                                                                        </figure>
                                                                    </div>
                                                                    <div
                                                                        className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"
                                                                        role="group"
                                                                        aria-roledescription="slide"
                                                                        aria-label="3 of 3"
                                                                        style={{ width: "353.333px", marginRight: 20 }}
                                                                        data-swiper-slide-index={2}
                                                                    >
                                                                        <figure className="swiper-slide-inner">
                                                                            <img
                                                                                decoding="async"
                                                                                className="swiper-slide-image"
                                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/ถอนได้สูงสุด-50-ล้าน-Fairbet-1040x1040_0.jpg"
                                                                                alt="ถอนได้สูงสุด-50-ล้าน-Fairbet-1040x1040_0"
                                                                            />
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="elementor-swiper-button elementor-swiper-button-prev"
                                                                    role="button"
                                                                    tabIndex={0}
                                                                    aria-label="Previous slide"
                                                                >
                                                                    <i aria-hidden="true" className="eicon-chevron-left" />{" "}
                                                                </div>
                                                                <div
                                                                    className="elementor-swiper-button elementor-swiper-button-next"
                                                                    role="button"
                                                                    tabIndex={0}
                                                                    aria-label="Next slide"
                                                                >
                                                                    <i aria-hidden="true" className="eicon-chevron-right" />{" "}
                                                                </div>
                                                                <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
                                                                    <span
                                                                        className="swiper-pagination-bullet swiper-pagination-bullet-active"
                                                                        data-bullet-index={0}
                                                                        aria-label="Go to slide 1"
                                                                        role="button"
                                                                        tabIndex={0}
                                                                    />
                                                                    <span
                                                                        className="swiper-pagination-bullet"
                                                                        data-bullet-index={1}
                                                                        aria-label="Go to slide 2"
                                                                        role="button"
                                                                    />
                                                                    <span
                                                                        className="swiper-pagination-bullet"
                                                                        data-bullet-index={2}
                                                                        aria-label="Go to slide 3"
                                                                        role="button"
                                                                    />
                                                                </div>
                                                                <span
                                                                    className="swiper-notification"
                                                                    aria-live="assertive"
                                                                    aria-atomic="true"
                                                                />
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section
                                        data-particle_enable="false"
                                        data-particle-mobile-disabled="false"
                                        className="elementor-section elementor-inner-section elementor-element elementor-element-66456996 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                        data-id={66456996}
                                        data-element_type="section"
                                    >
                                        <div className="elementor-container elementor-column-gap-default">
                                            <div
                                                className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-3f573a07"
                                                data-id="3f573a07"
                                                data-element_type="column"
                                                data-settings='{"background_background":"gradient"}'
                                            >
                                                <div className="elementor-widget-wrap elementor-element-populated">
                                                    <div
                                                        className="elementor-element elementor-element-66fd425a elementor-widget elementor-widget-image"
                                                        data-id="66fd425a"
                                                        data-element_type="widget"
                                                        data-widget_type="image.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                            <img
                                                                loading="lazy"
                                                                decoding="async"
                                                                width={500}
                                                                height={500}
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/04.png"
                                                                className="attachment-large size-large wp-image-2663"
                                                                alt=""
                                                                srcSet="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/04.png 500w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/04-300x300.png 300w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/04-150x150.png 150w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/04-24x24.png 24w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/04-36x36.png 36w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/04-48x48.png 48w"
                                                                sizes="(max-width: 500px) 100vw, 500px"
                                                            />{" "}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="elementor-element elementor-element-37405abe elementor-widget elementor-widget-text-editor"
                                                        data-id="37405abe"
                                                        data-element_type="widget"
                                                        data-widget_type="text-editor.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                            <p>
                                                                <span style={{ backgroundColor: "transparent" }}>
                                                                    Fairbet
                                                                </span>
                                                                <strong>&nbsp;ไฮโล Hi-Low</strong>
                                                                <br />
                                                                <b>วางสูงต่ำที่ </b>Fairbet<b>&nbsp;casino</b>
                                                            </p>
                                                            <p>
                                                                ซึ่งนอกจากบาคาร่าออนไลน์แล้วนั้นเว็บไซต์ MGM5G casino
                                                                ของเรายังมีเกมการพนันอย่าง ไฮโลออนไลน์
                                                                ที่เป็นเกมการพนันพื้นบ้านที่ได้รับความนิยมจนต้องนำมาเพิ่มในคาสิโนออนไลน์
                                                                ซึ่ง ไฮโลออนไลน์ ของเราเล่นง่าย ได้เงินจริง
                                                                คุณอาจจะรวยภายในข้ามคืนหากเดิมพันกับเว็บของเรา
                                                            </p>{" "}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-4263065d"
                                                data-id="4263065d"
                                                data-element_type="column"
                                                data-settings='{"background_background":"gradient"}'
                                            >
                                                <div className="elementor-widget-wrap elementor-element-populated">
                                                    <div
                                                        className="elementor-element elementor-element-104e9dc4 elementor-widget elementor-widget-image"
                                                        data-id="104e9dc4"
                                                        data-element_type="widget"
                                                        data-widget_type="image.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                            <img
                                                                loading="lazy"
                                                                decoding="async"
                                                                width={500}
                                                                height={500}
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/01.png"
                                                                className="attachment-large size-large wp-image-2660"
                                                                alt=""
                                                                srcSet="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/01.png 500w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/01-300x300.png 300w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/01-150x150.png 150w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/01-24x24.png 24w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/01-36x36.png 36w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/01-48x48.png 48w"
                                                                sizes="(max-width: 500px) 100vw, 500px"
                                                            />{" "}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="elementor-element elementor-element-12986f7e elementor-widget elementor-widget-text-editor"
                                                        data-id="12986f7e"
                                                        data-element_type="widget"
                                                        data-widget_type="text-editor.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                            <p>
                                                                <span style={{ backgroundColor: "transparent" }}>
                                                                    Fairbet
                                                                </span>
                                                                <strong>&nbsp;ไฮโล Hi-Low</strong>
                                                                <br />
                                                                <b>วางสูงต่ำที่ </b>Fairbet<b>&nbsp;casino</b>
                                                            </p>
                                                            <p>
                                                                ซึ่งนอกจากบาคาร่าออนไลน์แล้วนั้นเว็บไซต์ MGM5G casino
                                                                ของเรายังมีเกมการพนันอย่าง ไฮโลออนไลน์
                                                                ที่เป็นเกมการพนันพื้นบ้านที่ได้รับความนิยมจนต้องนำมาเพิ่มในคาสิโนออนไลน์
                                                                ซึ่ง ไฮโลออนไลน์ ของเราเล่นง่าย ได้เงินจริง
                                                                คุณอาจจะรวยภายในข้ามคืนหากเดิมพันกับเว็บของเรา
                                                            </p>{" "}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-68c636c3"
                                                data-id="68c636c3"
                                                data-element_type="column"
                                                data-settings='{"background_background":"gradient"}'
                                            >
                                                <div className="elementor-widget-wrap elementor-element-populated">
                                                    <div
                                                        className="elementor-element elementor-element-18f1c2fc elementor-widget elementor-widget-image"
                                                        data-id="18f1c2fc"
                                                        data-element_type="widget"
                                                        data-widget_type="image.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                            <img
                                                                loading="lazy"
                                                                decoding="async"
                                                                width={500}
                                                                height={500}
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/02.png"
                                                                className="attachment-large size-large wp-image-2661"
                                                                alt=""
                                                                srcSet="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/02.png 500w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/02-300x300.png 300w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/02-150x150.png 150w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/02-24x24.png 24w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/02-36x36.png 36w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/02-48x48.png 48w"
                                                                sizes="(max-width: 500px) 100vw, 500px"
                                                            />{" "}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="elementor-element elementor-element-7c9b5321 elementor-widget elementor-widget-text-editor"
                                                        data-id="7c9b5321"
                                                        data-element_type="widget"
                                                        data-widget_type="text-editor.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                            <p>
                                                                <span style={{ backgroundColor: "transparent" }}>
                                                                    Fairbet
                                                                </span>
                                                                <strong>&nbsp;ไฮโล Hi-Low</strong>
                                                                <br />
                                                                <b>วางสูงต่ำที่ </b>Fairbet<b>&nbsp;casino</b>
                                                            </p>
                                                            <p>
                                                                ซึ่งนอกจากบาคาร่าออนไลน์แล้วนั้นเว็บไซต์ MGM5G casino
                                                                ของเรายังมีเกมการพนันอย่าง ไฮโลออนไลน์
                                                                ที่เป็นเกมการพนันพื้นบ้านที่ได้รับความนิยมจนต้องนำมาเพิ่มในคาสิโนออนไลน์
                                                                ซึ่ง ไฮโลออนไลน์ ของเราเล่นง่าย ได้เงินจริง
                                                                คุณอาจจะรวยภายในข้ามคืนหากเดิมพันกับเว็บของเรา
                                                            </p>{" "}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-4cd621da"
                                                data-id="4cd621da"
                                                data-element_type="column"
                                                data-settings='{"background_background":"gradient"}'
                                            >
                                                <div className="elementor-widget-wrap elementor-element-populated">
                                                    <div
                                                        className="elementor-element elementor-element-4f50031a elementor-widget elementor-widget-image"
                                                        data-id="4f50031a"
                                                        data-element_type="widget"
                                                        data-widget_type="image.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                            <img
                                                                loading="lazy"
                                                                decoding="async"
                                                                width={500}
                                                                height={500}
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/03.png"
                                                                className="attachment-large size-large wp-image-2662"
                                                                alt=""
                                                                srcSet="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/03.png 500w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/03-300x300.png 300w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/03-150x150.png 150w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/03-24x24.png 24w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/03-36x36.png 36w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/03-48x48.png 48w"
                                                                sizes="(max-width: 500px) 100vw, 500px"
                                                            />{" "}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="elementor-element elementor-element-1d89fa70 elementor-widget elementor-widget-text-editor"
                                                        data-id="1d89fa70"
                                                        data-element_type="widget"
                                                        data-widget_type="text-editor.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                            <p>
                                                                <span style={{ backgroundColor: "transparent" }}>
                                                                    Fairbet
                                                                </span>
                                                                <strong>&nbsp;ไฮโล Hi-Low</strong>
                                                                <br />
                                                                <b>วางสูงต่ำที่ </b>Fairbet<b>&nbsp;casino</b>
                                                            </p>
                                                            <p>
                                                                ซึ่งนอกจากบาคาร่าออนไลน์แล้วนั้นเว็บไซต์ MGM5G casino
                                                                ของเรายังมีเกมการพนันอย่าง ไฮโลออนไลน์
                                                                ที่เป็นเกมการพนันพื้นบ้านที่ได้รับความนิยมจนต้องนำมาเพิ่มในคาสิโนออนไลน์
                                                                ซึ่ง ไฮโลออนไลน์ ของเราเล่นง่าย ได้เงินจริง
                                                                คุณอาจจะรวยภายในข้ามคืนหากเดิมพันกับเว็บของเรา
                                                            </p>{" "}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section
                        data-particle_enable="false"
                        data-particle-mobile-disabled="false"
                        className="elementor-section elementor-top-section elementor-element elementor-element-69062e1 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="69062e1"
                        data-element_type="section"
                        data-settings='{"background_background":"classic"}'
                    >
                        <div className="elementor-background-overlay" />
                        <div className="elementor-container elementor-column-gap-no">
                            <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-17c6e40"
                                data-id="17c6e40"
                                data-element_type="column"
                            >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <section
                                        data-particle_enable="false"
                                        data-particle-mobile-disabled="false"
                                        className="elementor-section elementor-inner-section elementor-element elementor-element-732046c elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                        data-id="732046c"
                                        data-element_type="section"
                                        data-settings='{"background_background":"classic"}'
                                    >
                                        <div className="elementor-container elementor-column-gap-default">
                                            <div
                                                className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-e6f0dae"
                                                data-id="e6f0dae"
                                                data-element_type="column"
                                                data-settings='{"background_background":"classic"}'
                                            >
                                                <div className="elementor-widget-wrap elementor-element-populated">
                                                    <div
                                                        className="elementor-element elementor-element-d776148 elementor-widget elementor-widget-theme-site-logo elementor-widget-image"
                                                        data-id="d776148"
                                                        data-element_type="widget"
                                                        data-widget_type="theme-site-logo.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                            <a href="https://ideabet.live/theme/Fairbet">
                                                                <img
                                                                    loading="lazy"
                                                                    decoding="async"
                                                                    width={788}
                                                                    height={812}
                                                                    src="/build/web/igame-index-lobby-wm/img/logo.png"
                                                                    className="attachment-full size-full wp-image-2648"
                                                                    alt=""
                                                                    srcSet="/build/web/igame-index-lobby-wm/img/logo.png 788w, /build/web/igame-index-lobby-wm/img/logo.png 291w, /build/web/igame-index-lobby-wm/img/logo.png 768w, /build/web/igame-index-lobby-wm/img/logo.png 24w, /build/web/igame-index-lobby-wm/img/logo.png 36w, h/build/web/igame-index-lobby-wm/img/logo.png 48w"
                                                                    sizes="(max-width: 788px) 100vw, 788px"
                                                                />{" "}
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="elementor-element elementor-element-87dfad6 elementor-widget elementor-widget-heading"
                                                        data-id="87dfad6"
                                                        data-element_type="widget"
                                                        data-widget_type="heading.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                            <div className="elementor-heading-title elementor-size-default">
                                                                สนุก และ คว้าชัยชนะได้ทุกที่ทุกเวลา
                                                            </div>{" "}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="elementor-element elementor-element-16697a8 elementor-widget elementor-widget-text-editor"
                                                        data-id="16697a8"
                                                        data-element_type="widget"
                                                        data-widget_type="text-editor.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                            <p>
                                                                ให้บริการความสนุกไม่รู้จบ 24 ชม. ไปกับดีลเลอร์ “DEALER”
                                                                สาวสวย สุดเซ็กซี่ คอยแจกไพ่ ให้คุณได้ลุ้นทุกเกมส์เดิมพัน
                                                                สบายตา สบายใจ ด้วย สาวๆ น่ารัก สุดเซ็กซี่ มาใส่บิกินี่
                                                                แต่ละสีแต่ละวันสีสด มาแจกไพ่
                                                                ท่านจะได้รับประสบการณ์ใหม่ในการเดิมพัน
                                                            </p>{" "}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section
                                        data-particle_enable="false"
                                        data-particle-mobile-disabled="false"
                                        className="elementor-section elementor-inner-section elementor-element elementor-element-b9f0eb7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                        data-id="b9f0eb7"
                                        data-element_type="section"
                                    >
                                        <div className="elementor-container elementor-column-gap-default">
                                            <div
                                                className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-409efef"
                                                data-id="409efef"
                                                data-element_type="column"
                                            >
                                                <div className="elementor-widget-wrap elementor-element-populated">
                                                    <div
                                                        className="elementor-element elementor-element-2ded2f7 elementor-widget elementor-widget-image"
                                                        data-id="2ded2f7"
                                                        data-element_type="widget"
                                                        data-widget_type="image.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                            <img
                                                                loading="lazy"
                                                                decoding="async"
                                                                width={841}
                                                                height={819}
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/ราชินี.png"
                                                                className="attachment-full size-full wp-image-2689"
                                                                alt=""
                                                                srcSet="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/ราชินี.png 841w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/ราชินี-300x292.png 300w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/ราชินี-768x748.png 768w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/ราชินี-24x24.png 24w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/ราชินี-36x36.png 36w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/ราชินี-48x48.png 48w"
                                                                sizes="(max-width: 841px) 100vw, 841px"
                                                            />{" "}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-b612b10"
                                                data-id="b612b10"
                                                data-element_type="column"
                                            >
                                                <div className="elementor-widget-wrap elementor-element-populated">
                                                    <div
                                                        className="elementor-element elementor-element-5868600 elementor-widget elementor-widget-heading"
                                                        data-id={5868600}
                                                        data-element_type="widget"
                                                        data-widget_type="heading.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                            <div className="elementor-heading-title elementor-size-default">
                                                                Fairbet เว็บคาสิโนสด ยอดนิยมแห่งปี
                                                            </div>{" "}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="elementor-element elementor-element-cab33ee elementor-widget elementor-widget-text-editor"
                                                        data-id="cab33ee"
                                                        data-element_type="widget"
                                                        data-widget_type="text-editor.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                            <p>
                                                                Fairbet
                                                                ผู้ให้บริการคาสิโนออนไลน์มีการพัฒนาและแก้ไขระบบอย่างดีที่สุดด้วยทีมงานผู้เชี่ยวชาญ
                                                                ที่คอยช่วยเหลือนักพนันตลอดการเดิมพันเมื่อท่านเกิดปัญหาใดๆ
                                                                อีกทั้งเราคือผู้ให้บริการพนันออนไลน์
                                                                ที่มีรูปแบบของเกมให้ท่านได้เลือกรับความบันเทิงอย่างหลากหลาย
                                                                และนอกจากนี้ท่านก็จะได้พบกับโปรโมชั่นสุดคุ้มแบบจัดเต็ม
                                                                มอบค่าตอบแทนจากการลงทุน
                                                                ในแบบที่ท่านไม่เคยได้จากที่ไหนมาก่อน
                                                            </p>{" "}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section
                        data-particle_enable="false"
                        data-particle-mobile-disabled="false"
                        className="elementor-section elementor-top-section elementor-element elementor-element-a1956e0 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="a1956e0"
                        data-element_type="section"
                        data-settings='{"background_background":"classic"}'
                    >
                        <div className="elementor-background-overlay" />
                        <div className="elementor-container elementor-column-gap-default">
                            <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3835a7c2"
                                data-id="3835a7c2"
                                data-element_type="column"
                            >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                        className="elementor-element elementor-element-8b4c08e elementor-widget elementor-widget-image"
                                        data-id="8b4c08e"
                                        data-element_type="widget"
                                        data-widget_type="image.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <img
                                                loading="lazy"
                                                decoding="async"
                                                width={752}
                                                height={95}
                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2022/06/imgtext-image-home-min.png"
                                                className="attachment-large size-large wp-image-2125"
                                                alt=""
                                                srcSet="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2022/06/imgtext-image-home-min.png 752w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2022/06/imgtext-image-home-min-300x38.png 300w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2022/06/imgtext-image-home-min-24x3.png 24w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2022/06/imgtext-image-home-min-36x5.png 36w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2022/06/imgtext-image-home-min-48x6.png 48w"
                                                sizes="(max-width: 752px) 100vw, 752px"
                                            />{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-53fb0d2e elementor-widget elementor-widget-image"
                                        data-id="53fb0d2e"
                                        data-element_type="widget"
                                        data-widget_type="image.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <img
                                                loading="lazy"
                                                decoding="async"
                                                width={1344}
                                                height={744}
                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/9D6189AD-B1F7-4F56-897C-C389B070D968.png"
                                                className="attachment-full size-full wp-image-2687"
                                                alt=""
                                                srcSet="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/9D6189AD-B1F7-4F56-897C-C389B070D968.png 1344w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/9D6189AD-B1F7-4F56-897C-C389B070D968-300x166.png 300w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/9D6189AD-B1F7-4F56-897C-C389B070D968-1024x567.png 1024w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/9D6189AD-B1F7-4F56-897C-C389B070D968-768x425.png 768w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/9D6189AD-B1F7-4F56-897C-C389B070D968-24x13.png 24w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/9D6189AD-B1F7-4F56-897C-C389B070D968-36x20.png 36w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2024/01/9D6189AD-B1F7-4F56-897C-C389B070D968-48x27.png 48w"
                                                sizes="(max-width: 1344px) 100vw, 1344px"
                                            />{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-2affe47f elementor-widget elementor-widget-heading"
                                        data-id="2affe47f"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <div className="elementor-heading-title elementor-size-default">
                                                เว็บอันดับหนึ่ง Fairbet ที่รวมสาวสวยมากมายให้ท่านได้ตื่นตาตื่นใจ
                                                บาคาร่าออนไลน์ที่ เย้ายวนใจที่สุดในตอนนี้ หรือ ที่รู้จักกันว่า
                                                Sexy Baccarat, Sexy Gaming, เอ็มจีเอ็มห้าจี เซ็กซี่ หรือ
                                                เซ็กซี่เกมส์ เอ็มจีเอ็มห้าจี
                                            </div>{" "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section
                        data-particle_enable="false"
                        data-particle-mobile-disabled="false"
                        className="elementor-section elementor-top-section elementor-element elementor-element-7177db0 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="7177db0"
                        data-element_type="section"
                        data-settings='{"background_background":"gradient"}'
                    >
                        <div className="elementor-container elementor-column-gap-default">
                            <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3d4a575"
                                data-id="3d4a575"
                                data-element_type="column"
                            >
                                <div className="elementor-widget-wrap elementor-element-populated e-swiper-container">
                                    <div
                                        className="elementor-element elementor-element-66f8f2ed elementor-widget elementor-widget-image-carousel e-widget-swiper"
                                        data-id="66f8f2ed"
                                        data-element_type="widget"
                                        data-settings='{"slides_to_show":"8","slides_to_show_tablet":"5","slides_to_show_mobile":"4","navigation":"none","autoplay_speed":0,"image_spacing_custom":{"unit":"px","size":25,"sizes":[]},"speed":4000,"autoplay":"yes","pause_on_hover":"yes","pause_on_interaction":"yes","infinite":"yes","image_spacing_custom_tablet":{"unit":"px","size":"","sizes":[]},"image_spacing_custom_mobile":{"unit":"px","size":"","sizes":[]}}'
                                        data-widget_type="image-carousel.default"
                                        aria-roledescription="carousel"
                                        aria-label="Carousel | Horizontal scrolling: Arrow Left & Right"
                                    >
                                        <div className="elementor-widget-container">
                                            <div
                                                className="elementor-image-carousel-wrapper swiper-container swiper-container-initialized swiper-container-horizontal"
                                                dir="ltr"
                                            >
                                                <div
                                                    className="elementor-image-carousel swiper-wrapper"
                                                    aria-live="off"
                                                    style={{
                                                        transform: "translate3d(-2003.75px, 0px, 0px)",
                                                        transitionDuration: "0ms"
                                                    }}
                                                >
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="5 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        aria-hidden="true"
                                                        inert=""
                                                        data-swiper-slide-index={4}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/dream-gaming.d993e67.png"
                                                                alt="dream-gaming.d993e67.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="6 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        aria-hidden="true"
                                                        inert=""
                                                        data-swiper-slide-index={5}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/gamatron.96cdfab.png"
                                                                alt="gamatron.96cdfab.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="7 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={6}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/joker-gaming.417d073.png"
                                                                alt="joker-gaming.417d073.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="8 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={7}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/live-22.dfd4972.png"
                                                                alt="live-22.dfd4972.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="9 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={8}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/pretty-gaming.f27ce57.png"
                                                                alt="pretty-gaming.f27ce57.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="10 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={9}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/sa-gaming.52e386a.png"
                                                                alt="sa-gaming.52e386a.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="11 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        aria-hidden="true"
                                                        inert=""
                                                        data-swiper-slide-index={10}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/sexy-gaming.09728c1.png"
                                                                alt="sexy-gaming.09728c1.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="12 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        aria-hidden="true"
                                                        inert=""
                                                        data-swiper-slide-index={11}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/spade-gaming.d835b4d.png"
                                                                alt="spade-gaming.d835b4d.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="1 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        aria-hidden="true"
                                                        inert=""
                                                        data-swiper-slide-index={0}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/ag-gaming.2eb0369.png"
                                                                alt="ag-gaming.2eb0369.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="2 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        aria-hidden="true"
                                                        inert=""
                                                        data-swiper-slide-index={1}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/ambbet.1d53780.png"
                                                                alt="ambbet.1d53780.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="3 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        aria-hidden="true"
                                                        inert=""
                                                        data-swiper-slide-index={2}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/ameba.dea3fdf.png"
                                                                alt="ameba.dea3fdf.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="4 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        aria-hidden="true"
                                                        inert=""
                                                        data-swiper-slide-index={3}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/dragoonsoft.a39781a.png"
                                                                alt="dragoonsoft.a39781a.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="5 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        aria-hidden="true"
                                                        inert=""
                                                        data-swiper-slide-index={4}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/dream-gaming.d993e67.png"
                                                                alt="dream-gaming.d993e67.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-prev"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="6 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        aria-hidden="true"
                                                        inert=""
                                                        data-swiper-slide-index={5}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/gamatron.96cdfab.png"
                                                                alt="gamatron.96cdfab.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-active"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="7 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={6}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/joker-gaming.417d073.png"
                                                                alt="joker-gaming.417d073.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-next"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="8 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={7}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/live-22.dfd4972.png"
                                                                alt="live-22.dfd4972.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="9 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={8}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/pretty-gaming.f27ce57.png"
                                                                alt="pretty-gaming.f27ce57.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="10 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={9}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/sa-gaming.52e386a.png"
                                                                alt="sa-gaming.52e386a.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="11 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        aria-hidden="true"
                                                        inert=""
                                                        data-swiper-slide-index={10}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/sexy-gaming.09728c1.png"
                                                                alt="sexy-gaming.09728c1.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="12 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        aria-hidden="true"
                                                        inert=""
                                                        data-swiper-slide-index={11}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/spade-gaming.d835b4d.png"
                                                                alt="spade-gaming.d835b4d.png"
                                                            />
                                                        </figure>
                                                    </div>{" "}
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="1 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        aria-hidden="true"
                                                        inert=""
                                                        data-swiper-slide-index={0}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/ag-gaming.2eb0369.png"
                                                                alt="ag-gaming.2eb0369.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="2 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        aria-hidden="true"
                                                        inert=""
                                                        data-swiper-slide-index={1}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/ambbet.1d53780.png"
                                                                alt="ambbet.1d53780.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="3 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        aria-hidden="true"
                                                        inert=""
                                                        data-swiper-slide-index={2}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/ameba.dea3fdf.png"
                                                                alt="ameba.dea3fdf.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="4 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        aria-hidden="true"
                                                        inert=""
                                                        data-swiper-slide-index={3}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/dragoonsoft.a39781a.png"
                                                                alt="dragoonsoft.a39781a.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="5 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        aria-hidden="true"
                                                        inert=""
                                                        data-swiper-slide-index={4}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/dream-gaming.d993e67.png"
                                                                alt="dream-gaming.d993e67.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="6 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        aria-hidden="true"
                                                        inert=""
                                                        data-swiper-slide-index={5}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/gamatron.96cdfab.png"
                                                                alt="gamatron.96cdfab.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="7 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={6}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/joker-gaming.417d073.png"
                                                                alt="joker-gaming.417d073.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
                                                        role="group"
                                                        aria-roledescription="slide"
                                                        aria-label="8 of 12"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={7}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2023/11/live-22.dfd4972.png"
                                                                alt="live-22.dfd4972.png"
                                                            />
                                                        </figure>
                                                    </div>
                                                </div>
                                                <span
                                                    className="swiper-notification"
                                                    aria-live="assertive"
                                                    aria-atomic="true"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-cca7ed2 elementor-widget elementor-widget-heading"
                                        data-id="cca7ed2"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <div className="elementor-heading-title elementor-size-default">
                                                Fairbet เว็บคาสิโนออนไลน์
                                            </div>{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-7ab7c41 elementor-widget elementor-widget-heading"
                                        data-id="7ab7c41"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <div className="elementor-heading-title elementor-size-default">
                                                ครองอันดับในใจคนไทย 10 ปีซ้อน
                                            </div>{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-2855b43 elementor-widget elementor-widget-heading"
                                        data-id="2855b43"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <div className="elementor-heading-title elementor-size-default">
                                                Fairbet บาคาร่า คาสิโนออนไลน์ ที่ดีที่สุด
                                                เพื่อประสบการณ์ที่ดีของผู้เล่นอย่างแท้จริง แบบ Fairbet
                                            </div>{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-c6f62f7 elementor-widget elementor-widget-heading"
                                        data-id="c6f62f7"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <div className="elementor-heading-title elementor-size-default">
                                                Created website by
                                            </div>{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-78b4f26 elementor-widget elementor-widget-site-logo"
                                        data-id="78b4f26"
                                        data-element_type="widget"
                                        data-settings='{"width":{"unit":"px","size":151,"sizes":[]},"width_tablet":{"unit":"px","size":"","sizes":[]},"width_mobile":{"unit":"px","size":"","sizes":[]},"align":"center","space":{"unit":"%","size":"","sizes":[]},"space_tablet":{"unit":"%","size":"","sizes":[]},"space_mobile":{"unit":"%","size":"","sizes":[]},"image_border_radius":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_space":{"unit":"px","size":0,"sizes":[]},"caption_space_tablet":{"unit":"px","size":"","sizes":[]},"caption_space_mobile":{"unit":"px","size":"","sizes":[]}}'
                                        data-widget_type="site-logo.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <div className="hfe-site-logo">
                                                <a
                                                    data-elementor-open-lightbox=""
                                                    className="elementor-clickable"
                                                    href="https://ideabet.live/theme/Fairbet"
                                                >
                                                    <div className="hfe-site-logo-set">
                                                        <div className="hfe-site-logo-container">
                                                            <img
                                                                decoding="async"
                                                                className="hfe-site-logo-img elementor-animation-"
                                                                src="/build/web/igame-index-lobby-wm/img/logo.png"
                                                                alt="default-logo"
                                                            />
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-f84090e elementor-widget elementor-widget-heading"
                                        data-id="f84090e"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <div className="elementor-heading-title elementor-size-default">
                                                Terms and Conditions
                                            </div>{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-20f83cd elementor-widget elementor-widget-heading"
                                        data-id="20f83cd"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <div className="elementor-heading-title elementor-size-default">
                                                Copyright © 2024 Fairbet All Rights Reserved.
                                            </div>{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-4018346 elementor-widget elementor-widget-image"
                                        data-id={4018346}
                                        data-element_type="widget"
                                        data-widget_type="image.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <img
                                                loading="lazy"
                                                decoding="async"
                                                width={1024}
                                                height={91}
                                                src="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2022/06/mfooter-1024x91.png"
                                                className="attachment-large size-large wp-image-2130"
                                                alt=""
                                                srcSet="https://ideabet.live/theme/hatlnw888/wp-content/uploads/2022/06/mfooter-1024x91.png 1024w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2022/06/mfooter-300x27.png 300w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2022/06/mfooter-768x68.png 768w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2022/06/mfooter-24x2.png 24w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2022/06/mfooter-36x3.png 36w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2022/06/mfooter-48x4.png 48w, https://ideabet.live/theme/hatlnw888/wp-content/uploads/2022/06/mfooter.png 1495w"
                                                sizes="(max-width: 1024px) 100vw, 1024px"
                                            />{" "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <section
                        className="elementor-section elementor-inner-section elementor-element elementor-element-02c5561 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="02c5561"
                        data-element_type="section"
                        data-settings='{"background_background":"classic"}'
                    >
                        <div className="elementor-container elementor-column-gap-default">
                            <div
                                className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-710fcd5"
                                data-id="710fcd5"
                                data-element_type="column"
                            >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                        className="elementor-element elementor-element-ccafdf7 elementor-widget elementor-widget-image"
                                        data-id="ccafdf7"
                                        data-element_type="widget"
                                        data-widget_type="image.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <img
                                                loading="lazy"
                                                decoding="async"
                                                width={806}
                                                height={806}
                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/Calendar_corner_red.png"
                                                className="attachment-large size-large"
                                                alt=""
                                                srcSet="https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/Calendar_corner_red.png 806w, https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/Calendar_corner_red-300x300.png 300w, https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/Calendar_corner_red-150x150.png 150w, https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/Calendar_corner_red-768x768.png 768w, https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/Calendar_corner_red-24x24.png 24w, https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/Calendar_corner_red-36x36.png 36w, https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/Calendar_corner_red-48x48.png 48w"
                                                sizes="(max-width: 806px) 100vw, 806px"
                                            />{" "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f130389"
                                data-id="f130389"
                                data-element_type="column"
                                data-settings='{"background_background":"classic"}'
                            >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                        className="elementor-element elementor-element-5ad0a04 elementor-widget elementor-widget-site-logo"
                                        data-id="5ad0a04"
                                        data-element_type="widget"
                                        data-settings='{"width":{"unit":"px","size":334,"sizes":[]},"width_tablet":{"unit":"px","size":"","sizes":[]},"width_mobile":{"unit":"px","size":"","sizes":[]},"align":"center","space":{"unit":"%","size":"","sizes":[]},"space_tablet":{"unit":"%","size":"","sizes":[]},"space_mobile":{"unit":"%","size":"","sizes":[]},"image_border_radius":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_space":{"unit":"px","size":0,"sizes":[]},"caption_space_tablet":{"unit":"px","size":"","sizes":[]},"caption_space_mobile":{"unit":"px","size":"","sizes":[]}}'
                                        data-widget_type="site-logo.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <div className="hfe-site-logo">
                                                <a
                                                    data-elementor-open-lightbox=""
                                                    className="elementor-clickable"
                                                    href="https://ideabet.live/theme/harem789"
                                                >
                                                    <div className="hfe-site-logo-set">
                                                        <div className="hfe-site-logo-container">
                                                            <img
                                                                decoding="async"
                                                                className="hfe-site-logo-img elementor-animation-"
                                                                src="/build/web/igame-index-lobby-wm/img/logo.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-7cac728 elementor-widget elementor-widget-heading"
                                        data-id="7cac728"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <h2 className="elementor-heading-title elementor-size-default">
                                                สนุก และ คว้าชัยชนะได้ทุกที่ทุกเวลา
                                            </h2>{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-6c6b4fc elementor-widget elementor-widget-text-editor"
                                        data-id="6c6b4fc"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <p>
                                                ให้บริการความสนุกไม่รู้จบ 24 ชม. ไปกับดีลเลอร์ “DEALER” สาวสวย
                                                สุดเซ็กซี่ คอยแจกไพ่ ให้คุณได้ลุ้นทุกเกมส์เดิมพัน สบายตา สบายใจ
                                                ด้วย สาวๆ น่ารัก สุดเซ็กซี่ มาใส่บิกินี่ แต่ละสีแต่ละวันสีสด
                                                มาแจกไพ่ ท่านจะได้รับประสบการณ์ใหม่ในการเดิมพัน
                                            </p>{" "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-97f8a74 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="97f8a74"
                        data-element_type="section"
                        data-settings='{"background_background":"classic"}'
                    >
                        <div className="elementor-background-overlay" />
                        <div className="elementor-container elementor-column-gap-default">
                            <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4992347"
                                data-id={4992347}
                                data-element_type="column"
                            >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                        className="elementor-element elementor-element-8b4c08e elementor-widget elementor-widget-image"
                                        data-id="8b4c08e"
                                        data-element_type="widget"
                                        data-widget_type="image.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <img
                                                loading="lazy"
                                                decoding="async"
                                                width={752}
                                                height={95}
                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/imgtext-image-home-min.png"
                                                className="attachment-large size-large"
                                                alt=""
                                                srcSet="https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/imgtext-image-home-min.png 752w, https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/imgtext-image-home-min-300x38.png 300w, https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/imgtext-image-home-min-24x3.png 24w, https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/imgtext-image-home-min-36x5.png 36w, https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/imgtext-image-home-min-48x6.png 48w"
                                                sizes="(max-width: 752px) 100vw, 752px"
                                            />{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-def9de3 elementor-widget elementor-widget-image"
                                        data-id="def9de3"
                                        data-element_type="widget"
                                        data-widget_type="image.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <img
                                                loading="lazy"
                                                decoding="async"
                                                width={768}
                                                height={315}
                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/สล็อต-PG-slot-all-666sx-1024x420-1-768x315.png"
                                                className="attachment-medium_large size-medium_large"
                                                alt=""
                                                srcSet="https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/สล็อต-PG-slot-all-666sx-1024x420-1-768x315.png 768w, https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/สล็อต-PG-slot-all-666sx-1024x420-1-300x123.png 300w, https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/สล็อต-PG-slot-all-666sx-1024x420-1-24x10.png 24w, https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/สล็อต-PG-slot-all-666sx-1024x420-1-36x15.png 36w, https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/สล็อต-PG-slot-all-666sx-1024x420-1-48x20.png 48w, https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/สล็อต-PG-slot-all-666sx-1024x420-1.png 1024w"
                                                sizes="(max-width: 768px) 100vw, 768px"
                                            />{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-fb42a19 elementor-widget elementor-widget-heading"
                                        data-id="fb42a19"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <h2 className="elementor-heading-title elementor-size-default">
                                                เว็บอันดับหนึ่ง Fairbet
                                                ที่รวมสาวสวยมากมายให้ท่านได้ตื่นตาตื่นใจ บาคาร่าออนไลน์ที่
                                                เย้ายวนใจที่สุดในตอนนี้ หรือ ที่รู้จักกันว่า Sexy Baccarat, Sexy
                                                Gaming, เอ็มจีเอ็มห้าจี เซ็กซี่ หรือ เซ็กซี่เกมส์
                                                เอ็มจีเอ็มห้าจี
                                            </h2>{" "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-908289f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="908289f"
                        data-element_type="section"
                        data-settings='{"background_background":"gradient"}'
                    >
                        <div className="elementor-container elementor-column-gap-default">
                            <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-01e41b9"
                                data-id="01e41b9"
                                data-element_type="column"
                            >
                                <div className="elementor-widget-wrap elementor-element-populated e-swiper-container">
                                    <div
                                        className="elementor-element elementor-element-82bf8e2 elementor-widget elementor-widget-image-carousel e-widget-swiper"
                                        data-id="82bf8e2"
                                        data-element_type="widget"
                                        data-settings='{"slides_to_show":"8","slides_to_show_tablet":"5","slides_to_show_mobile":"4","navigation":"none","autoplay_speed":1000,"image_spacing_custom":{"unit":"px","size":25,"sizes":[]},"autoplay":"yes","pause_on_hover":"yes","pause_on_interaction":"yes","infinite":"yes","speed":500}'
                                        data-widget_type="image-carousel.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <div
                                                className="elementor-image-carousel-wrapper swiper-container swiper-container-initialized swiper-container-horizontal"
                                                dir="ltr"
                                            >
                                                <div
                                                    className="elementor-image-carousel swiper-wrapper"
                                                    style={{
                                                        transform: "translate3d(-2433.12px, 0px, 0px)",
                                                        transitionDuration: "0ms"
                                                    }}
                                                >
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={4}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/dream-gaming.d993e67.png"
                                                                alt="dream-gaming.d993e67"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={5}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/gamatron.96cdfab.png"
                                                                alt="gamatron.96cdfab"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={6}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/joker-gaming.417d073.png"
                                                                alt="joker-gaming.417d073"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={7}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/live-22.dfd4972.png"
                                                                alt="live-22.dfd4972"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={8}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/pretty-gaming.f27ce57.png"
                                                                alt="pretty-gaming.f27ce57"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={9}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/sa-gaming.52e386a.png"
                                                                alt="sa-gaming.52e386a"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={10}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/sexy-gaming.09728c1.png"
                                                                alt="sexy-gaming.09728c1"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={11}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/spade-gaming.d835b4d.png"
                                                                alt="spade-gaming.d835b4d"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={0}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/ag-gaming.2eb0369.png"
                                                                alt="ag-gaming.2eb0369"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={1}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/ambbet.1d53780.png"
                                                                alt="ambbet.1d53780"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={2}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/ameba.dea3fdf.png"
                                                                alt="ameba.dea3fdf"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={3}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/dragoonsoft.a39781a.png"
                                                                alt="dragoonsoft.a39781a"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={4}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/dream-gaming.d993e67.png"
                                                                alt="dream-gaming.d993e67"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={5}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/gamatron.96cdfab.png"
                                                                alt="gamatron.96cdfab"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={6}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/joker-gaming.417d073.png"
                                                                alt="joker-gaming.417d073"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={7}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/live-22.dfd4972.png"
                                                                alt="live-22.dfd4972"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-prev"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={8}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/pretty-gaming.f27ce57.png"
                                                                alt="pretty-gaming.f27ce57"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-active"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={9}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/sa-gaming.52e386a.png"
                                                                alt="sa-gaming.52e386a"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-next"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={10}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/sexy-gaming.09728c1.png"
                                                                alt="sexy-gaming.09728c1"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={11}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/spade-gaming.d835b4d.png"
                                                                alt="spade-gaming.d835b4d"
                                                            />
                                                        </figure>
                                                    </div>{" "}
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={0}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/ag-gaming.2eb0369.png"
                                                                alt="ag-gaming.2eb0369"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={1}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/ambbet.1d53780.png"
                                                                alt="ambbet.1d53780"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={2}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/ameba.dea3fdf.png"
                                                                alt="ameba.dea3fdf"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={3}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/dragoonsoft.a39781a.png"
                                                                alt="dragoonsoft.a39781a"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={4}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/dream-gaming.d993e67.png"
                                                                alt="dream-gaming.d993e67"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={5}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/gamatron.96cdfab.png"
                                                                alt="gamatron.96cdfab"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={6}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/joker-gaming.417d073.png"
                                                                alt="joker-gaming.417d073"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={7}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/03/live-22.dfd4972.png"
                                                                alt="live-22.dfd4972"
                                                            />
                                                        </figure>
                                                    </div>
                                                </div>
                                                <span
                                                    className="swiper-notification"
                                                    aria-live="assertive"
                                                    aria-atomic="true"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-7177db0 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="7177db0"
                        data-element_type="section"
                        data-settings='{"background_background":"gradient"}'
                    >
                        <div className="elementor-container elementor-column-gap-default">
                            <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3d4a575"
                                data-id="3d4a575"
                                data-element_type="column"
                            >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                        className="elementor-element elementor-element-cca7ed2 elementor-widget elementor-widget-heading"
                                        data-id="cca7ed2"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <h2 className="elementor-heading-title elementor-size-default">
                                                Fairbetเว็บคาสิโนออนไลน์
                                            </h2>{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-7ab7c41 elementor-widget elementor-widget-heading"
                                        data-id="7ab7c41"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <h2 className="elementor-heading-title elementor-size-default">
                                                ครองอันดับในใจคนไทย 10 ปีซ้อน
                                            </h2>{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-2855b43 elementor-widget elementor-widget-heading"
                                        data-id="2855b43"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <h2 className="elementor-heading-title elementor-size-default">
                                                Fairbetบาคาร่า คาสิโนออนไลน์ ที่ดีที่สุด
                                                เพื่อประสบการณ์ที่ดีของผู้เล่นอย่างแท้จริง แบบ HAREM789
                                            </h2>{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-c6f62f7 elementor-widget elementor-widget-heading"
                                        data-id="c6f62f7"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <h2 className="elementor-heading-title elementor-size-default">
                                                Created website by
                                            </h2>{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-78b4f26 elementor-widget elementor-widget-site-logo"
                                        data-id="78b4f26"
                                        data-element_type="widget"
                                        data-settings='{"width":{"unit":"px","size":256,"sizes":[]},"width_tablet":{"unit":"px","size":"","sizes":[]},"width_mobile":{"unit":"px","size":"","sizes":[]},"align":"center","space":{"unit":"%","size":"","sizes":[]},"space_tablet":{"unit":"%","size":"","sizes":[]},"space_mobile":{"unit":"%","size":"","sizes":[]},"image_border_radius":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_space":{"unit":"px","size":0,"sizes":[]},"caption_space_tablet":{"unit":"px","size":"","sizes":[]},"caption_space_mobile":{"unit":"px","size":"","sizes":[]}}'
                                        data-widget_type="site-logo.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <div className="hfe-site-logo">
                                                <a
                                                    data-elementor-open-lightbox=""
                                                    className="elementor-clickable"
                                                    href="/"
                                                >
                                                    <div className="hfe-site-logo-set">
                                                        <div className="hfe-site-logo-container">
                                                            <img
                                                                decoding="async"
                                                                className="hfe-site-logo-img elementor-animation-"
                                                                src="/build/web/igame-index-lobby-wm/img/logo.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-f84090e elementor-widget elementor-widget-heading"
                                        data-id="f84090e"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <h2 className="elementor-heading-title elementor-size-default">
                                                Terms and Conditions
                                            </h2>{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-20f83cd elementor-widget elementor-widget-heading"
                                        data-id="20f83cd"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <h2 className="elementor-heading-title elementor-size-default">
                                                Copyright © 2021 FairbetAll Rights Reserved.
                                            </h2>{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-4018346 elementor-widget elementor-widget-image"
                                        data-id={4018346}
                                        data-element_type="widget"
                                        data-widget_type="image.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <img
                                                loading="lazy"
                                                decoding="async"
                                                width={1024}
                                                height={91}
                                                src="https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/mfooter-1024x91.png"
                                                className="attachment-large size-large"
                                                alt=""
                                                srcSet="https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/mfooter-1024x91.png 1024w, https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/mfooter-300x27.png 300w, https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/mfooter-768x68.png 768w, https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/mfooter-24x2.png 24w, https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/mfooter-36x3.png 36w, https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/mfooter-48x4.png 48w, https://ideabet.live/theme/harem789/wp-content/uploads/2022/06/mfooter.png 1495w"
                                                sizes="(max-width: 1024px) 100vw, 1024px"
                                            />{" "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
                </div>

                {/* <Carousel /> */}
            </>

        );
    }
}
export default App;
