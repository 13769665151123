import React, { Component } from 'react'; //different
import SweetAlert from 'react-bootstrap-sweetalert';
//import Paoyingchub from './game/paoyingchub';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
//import './build/web/igame-index-lobby-wm/style.a57427aa.css';
import { isMobile } from 'react-device-detect';
import NavMenu from './navmenu';
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
import Banner from './banner';
import MoblieMenu from "./mobilemenu";
import moment from 'moment';
import TextSlider from './textslide';
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            rewardlist: [],
            activePage: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            point: null,
            partner: null,
            Type: null,
            products_page_count: 1,
            userid: this.props.props.username,
            loginstate: this.props.props.loginstate,
            firstname: null,
            lastname: null,
            alert: null,
            typepage: null
        }
        // this.handleClick = this.handleClick.bind(this);
        // this.handlePageChange = this.handlePageChange.bind(this);
        // this.hideAlert = this.hideAlert.bind(this);

    }



    callpercent(gameid) {
        //console.log(this.state.typepage)
        const date = new Date();
        let number = 30 + Math.floor(Math.random() * (100 - 30));
        var tokenx = localStorage.getItem('percentgame');
        let tagpercentlist = [];
        let tagpercent = [];
        if (tokenx != null) {
            const listx = JSON.parse(tokenx);
            var listIndex = listx.filter(x => x.id === gameid);
            // console.log(57, listIndex);
            if (listIndex.length == 0) {
                //  console.log(gameid, 59);
                let list = {
                    id: gameid,
                    percent: number,
                    datetime: date
                }
                listx.push(list)
                tagpercent.push(listIndex)
                localStorage.setItem('percentgame', JSON.stringify(listx))
            } else {
                tagpercent = listIndex
                var created = new Date();
                const startDate = moment(listIndex[0].datetime);
                const timeEnd = moment(created);
                const diff = timeEnd.diff(startDate);
                const diffDuration = moment.duration(diff);
                // console.log(777, diffDuration.minutes(), gameid)
                if (diffDuration.minutes() > 30) {
                    listx.filter(x => x.id === gameid).map(function (obj) {
                        obj.number = number
                        obj.datetime = date
                        return obj
                    })
                    localStorage.setItem('percentgame', JSON.stringify(listx))
                    console.log("finish", gameid)
                }
            }
        }
        else {
            let list = {
                id: gameid,
                percent: number,
                datetime: date
            }
            tagpercent.push(list)
            tagpercentlist.push(list)
            localStorage.setItem('percentgame', JSON.stringify(tagpercentlist))
        }
        //   console.log(92, tagpercent)
        if (this.state.typepage != "CASINO" && this.state.typepage != "SPORT") {
            let pop = (
                <div>
                    <div className="progress position-progress mt-1 position-progress-g"
                        role="progressbar"
                        aria-label="Example with label"
                        aria-valuenow={tagpercent[0].percent}
                        aria-valuemin={0}
                        aria-valuemax={100}
                    >
                        <div
                            className="progress-bar bg-rate-persent text-web"
                            style={{
                                width: parseInt(tagpercent[0].percent) + "%",
                                background: (tagpercent[0].percent > 0 && tagpercent[0].percent < 50) ? "rgb(254, 215, 25)" : (tagpercent[0].percent >= 50 && tagpercent[0].percent < 70) ? " rgb(2, 119, 189)" : "rgb(37, 155, 36)"
                            }}
                        />
                    </div>
                    <div className="text-win-rate">
                        <small className="text-size-win-rate">
                            อัตราชนะ
                        </small>
                        <b className="p-1 text-size-win-rate-number">
                            {tagpercent[0].percent}%
                        </b>
                    </div>
                </div>
            )
            return pop
        }
        else {
            return ""
        }


    }


    redeem = (e, gameIdx, partner) => {
        var userAgent = navigator.userAgent
        console.log(userAgent,this.props.props.loginstate);
        if (!this.props.props.loginstate) {
            window.location.href = '/signin';
        }
        if (this.state.username !== null) {

            instance.post("/api/v1/tablauncher", {
                gameId: gameIdx,
                partner: partner,
                System: Systems,
                playerUsername:this.props.props.username,
                isMobile: isMobile,
                demo: false
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        console.log(res.data.message);
                        var test = res.data.message.launcher
                        if (test !== undefined)
                            if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
                                window.location.href = test.mobile;
                            }
                            else {
                                if (!isMobile) window.open(test.desktop, '_blank', 'height=1000,width=1000');
                                else window.open(test.mobile);
                            }
                        else if (test === undefined) {
                            Swal.fire({
                                title: 'error!',
                                text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                    }
                    else {
                        //  this.CheckThisGoalError();
                    }
                });
        }
        else {

        }


    }



    componentDidMount() {
        this.checkonline();
    }
    addDefaultSrc(ev, listitem) {
        const origin = listitem.split("http");
        if (origin.length > 1) {
            const origin = listitem.split("https");
            if (origin.length > 1) return listitem;
            else return listitem.replace("http", "https");
        }
        else {
            return "https://ardmhzelxcmj.ocrazeckyunc.com" + listitem

        }

    }
    async checkonline() {
        //////console.log(1);
        const search = window.location.href;
        const params = new URLSearchParams(search);
        const pathname = window.location.pathname.split("/")
        // const space = search.split("/")
        console.log(pathname);
        if (pathname.length > 2 && pathname[1] =="listgameall") {
            this.setState({
                typepage: pathname[2].replace("#", "").toLocaleUpperCase(),
                partner: pathname[3].replace("#", ""),
            });
            await instance.post("/api/v1/tabgamelist", {
                state: "999",
                System: Systems,
                partner: pathname[3].replace("#", ""),
                game: pathname[2].replace("#", ""),
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        this.setState({
                            categorylist: res.data.message
                        });
                    }
                });
        }
    }


    render() {
        if (this.state.categorylist.length > 0) {
            return (
                <>
                    <div>
                        <main className="container p-0">
                            <div className="p-1 text-left container-xl text-white mt-1 my5  ">
                                {/**/}
                                <div className="clearfix">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h4 className="ps-2 mb-0 f-22 text-title">
                                                <b>สล็อต</b>
                                            </h4>
                                        </div>
                                        <div>
                                            <img role="button" alt="" className="w-icon-cr-bl-record-all" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-1 pb-3 text-white w-scroll-height">
                                <div className="x-category-provider js-game-scroll-container js-game-container  ">
                                    <div className="-games-list-container container">
                                        <nav className="nav-menu" id="navbarProvider">
                                            <ul className="nav nav-pills row  ">
                                                {this.state.categorylist.map((listitem, i) => (
                                                    <li className="col-4 col-lg-4 col-md-4 nav-item card-width -random-container -game-sport-macro-container p-1 pb-0 mb-2">
                                                        <a key={i} id={listitem.gameid}
                                                            href={() => false}
                                                            onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}
                                                        >
                                                            <div
                                                                className="x-game-list-item-macro js-game-list-toggle -big -cannot-entry -untestable example"
                                                                data-status="-cannot-entry -untestable"
                                                            >
                                                                <div className="-inner-wrapper card-width-banner">
                                                                    <div>
                                                                        {/**/}
                                                                        {/**/}
                                                                        {/**/}
                                                                    </div>
                                                                    <picture>
                                                                        <img
                                                                            role="button"
                                                                            className="w-100 hover-slot hoverTextCss"
                                                                            lazy="loaded"
                                                                            data-src={listitem.image}
                                                                            src={listitem.image}
                                                                        />
                                                                    </picture>
                                                                    <div className="-overlay d-none d-lg-block">
                                                                        <div className="-overlay-inner">
                                                                            <div className="-wrapper-container">
                                                                                <a href={() => false}
                                                                                    className="-btn -btn-play js-account-approve-aware"  >
                                                                                    <i className="fas fa-play" />
                                                                                    <span className="-text-btn">เข้าเล่น</span>
                                                                                </a>
                                                                                <a
                                                                                    id="loginmode"
                                                                                    href="#loginModal"
                                                                                    className="-btn -btn-play js-account-approve-aware"
                                                                                    data-toggle="modal"
                                                                                    data-target="#loginModal"
                                                                                    style={{ display: "none" }}
                                                                                >
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="-title mt-1 mb-0 text-center text-color-name-game">
                                                                    {listitem.description}
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </li>

                                                ))}
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </main >
                    </div >

                </>

            );
        } else {

        }
    }
}
export default App;
