import React, { Component } from 'react'; //different
//import './style.css';
import instance from "./axios-instance";
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
import NavMenu from './navmenu';
import Systems from "./SystemFunction";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

var settings = {
  dots: true,
  infinite: true,
  slidesToShow: (window.innerWidth <= 760) ? 1 : 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 3000,
  cssEase: "linear"
};

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      textslide: "เว็บใหญ่ ไม่ล็อคยูส ฝาก-ถอน โอนไว 10วินาที ไม่มีขั้นต่ำ ไม่มีเทิร์น ไม่จำกัดถอน คืนค่าคอมสล็อต1%  คาสิโน 0.8% กีฬา คืน 0.8% คืนยอดเสีย5ทุกค่าย % แนะนำเพื่อน0.8% แอดมินดูแลตลอด24ชม.",
    }

  }
  async componentDidMount() {
    var token = localStorage.getItem('auth_token');
    const search = window.location.href;
    const params = new URLSearchParams(search);

  }

  render() {
    const { textslide } = this.state
    return (
      <div className="newsboxhead"  data-animatable="" data-delat={200}>
        <div className="-icon-container">
          <i className="fas fa-volume-up" />
        </div>
        <span>
          {textslide}
          <span>
            {" "}
            <div className="textwidget" />
          </span>
        </span>
      </div>

      //   <div className="m-0 bg  ">
      //   <div className="border-radius-maq-all bg-marquee-hh input-group input-group-sm w-h-marquee mt-2">
      //     <span className="input-group-text" id="inputGroup-sizing-sm">
      //       <svg
      //         xmlns="http://www.w3.org/2000/svg"
      //         width={16}
      //         height={16}
      //         fill="currentColor"
      //         className="bi bi-megaphone"
      //         viewBox="0 0 16 16"
      //       >
      //         <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49a68.14 68.14 0 0 0-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 74.663 74.663 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199V2.5zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0zm-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233c.18.01.359.022.537.036 2.568.189 5.093.744 7.463 1.993V3.85zm-9 6.215v-4.13a95.09 95.09 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A60.49 60.49 0 0 1 4 10.065zm-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68.019 68.019 0 0 0-1.722-.082z" />
      //       </svg>
      //     </span>

      //     <input type="text" value={textslide} className="form-control form-not-input-login-register marquee input-group-text " disabled=""/>
      //   </div>
      //   <marquee
      //     direction="right"
      //     // onMouseOver="stop()"
      //     // onMouseOut="start()"
      //     className="none-marquee li"
      //   />
      // </div>

    );
  }
}
export default App;
